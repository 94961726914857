<template>
  <v-container
    class="fill-height"
    :class="isPresensi ? 'background-green' : 'background-blue'"
    fluid
  >
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6" lg="4" xl="4" xs="12">
        <v-card-actions class="justify-center">
          <v-avatar color="white" class="my-5 pa-2" height="100" width="100">
            <v-img
              :src="require('@/assets/images/logo-tutwuri.png')"
              contain
              height="70"
            />
          </v-avatar>
        </v-card-actions>
        <p class="text-center ma-0 white--text">
          APLIKASI {{ isPresensi ? "PRESENSI" : "SIMPEG" }}
        </p>
        <p class="text-center font-weight-bold white--text">
          PAUD DIKDAS DIKMEN
        </p>
        <v-card class="rounded-lg mt-15">
          <v-card-text class="pt-5 px-0">
            <!-- <InputSelection
              class="mx-4"
              :value="isPresensi"
              textFalse="Simpeg"
              textTrue="Presensi"
              @on-change="val => (isPresensi = val)"
            /> -->
            <v-form
              id="check-login-form"
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="onSubmit(username, password)"
            >
              <v-row justify="center" class="px-4">
                <v-col cols="12" class="py-0">
                  <v-text-field
                    solo
                    flat
                    v-model="username"
                    :rules="usernameRules"
                    placeholder="Username"
                    required
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider class="mt-4 mb-2"></v-divider>
              <v-row justify="center" class="px-4">
                <v-col cols="12" class="pt-2">
                  <v-text-field
                    solo
                    flat
                    v-model="password"
                    :rules="passwordRules"
                    :append-icon="e0 ? 'visibility' : 'visibility_off'"
                    @click:append="() => (e0 = !e0)"
                    :type="e0 ? 'password' : 'text'"
                    placeholder="Password"
                    required
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-actions class="px-4">
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="getToPage('forgot.password')"
                >
                  <p
                    class="subtitle-2 primary-color ma-auto font-weight-regular text-capitalize"
                  >
                    Lupa Password?
                  </p>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
          <v-card-text class="button">
            <v-row cols="8" align="center" justify="center">
              <v-btn
                :disabled="!valid"
                :loading="formLoading"
                block
                text
                type="submit"
                form="check-login-form"
              >
                <p class="ma-auto text-capitalize">
                  Masuk
                </p>
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from "@/services/resources/auth.service";
// const InputSelection = () => import("@/components/Input/Selection");
import { mapSummaryRootField, mapAuthRootField } from "@/store/helpers";

export default {
  components: {
    // InputSelection
  },
  data() {
    return {
      e0: true,
      version: process.env.VERSION ?? null,
      formLoading: false,
      valid: true,
      e1: true,
      username: null,
      usernameRules: [
        v => !!v || "Username is required",
        v => !!v || "Username harus diisi",
        v => /^\S{1,}$/.test(v) || "Format username harus sesuai"
      ],
      password: null,
      passwordRules: [v => !!v || "Password is required"]
    };
  },
  computed: {
    ...mapSummaryRootField({
      isPresensi: "setting.isPresensi"
    }),
    ...mapAuthRootField({
      profile: "profile"
    })
  },
  methods: {
    onSubmit(username, password) {
      if (this.$refs.form.validate()) {
        this.login({
          username,
          password,
          platform: this.isPresensi ? "presensi" : "simpeg"
        });
      }
    },

    // Service
    async login(payload) {
      try {
        this.formLoading = true;
        await AuthService.login(payload)
          .then(response => {
            const { message, status, data } = response.data;
            if (status) {
              this.$store.commit("auth/setAuth", data);
              this.getProfile();
              if (this.$route.query && this.$route.query.pathname) {
                if (this.$route.query.pathname.includes("login")) {
                  this.getToPage("home");
                } else {
                  this.$router.replace(
                    this.$route.query.pathname + this.$route.query.search
                  );
                }
              } else {
                this.getToPage("home");
              }
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            this.$store.commit("snackbar/setSnack", {
              show: true,
              message: error.response.data.message,
              color: "error"
            });
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.log(err);
      }
    },
    async getProfile() {
      try {
        await AuthService.getProfile({ platform: process.env.VUE_APP_MODE })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.profile = this.$_.merge(this.profile, data);
            }
          })
          .catch(error => {
            throw new Error(error);
          });
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
<style>
.background-blue {
  background-color: #297bbf;
  height: "100%";
}
.background-green {
  background-color: #66bb6a;
  height: "100%";
}

.button {
  background-color: #fbb005;
}
</style>
